import React from 'react'
import styled from 'styled-components';
import cloud from '../../media/icon/cloud_neo.png';
import inventory from '../../media/icon/inventory_neo.png';
import responive from '../../media/icon/responsive_neo.png';
import user from '../../media/icon/user_neo.png';
import titleimg from '../../media/bgimages/neotitleimg3.png';
import TileSide from '../cardcomponents/tilebox';
import bgimg from '../../media/bgimages/neo_bg_im.jpg';

const PdtSrcDesc = () => {
    const PdtBox = styled.section`
        background: #fff;
        width: 100vw;
        height: 100vh ;
        display: flex ;
        align-items: center;
        padding: 0 100px;
        @media screen and (max-width: 1024px) {
            padding:70px;
        }
        @media screen and (max-width: 580px) {
            padding:40px;
        }
    `;
    const PdtInBox = styled.div`
        position: relative;
        width: 100%;
        height: 70vh;
        /* padding: 50px 0; */
        background-image: url(${bgimg});
        /* opacity: 0.5; */
        
        @media screen and (max-width: 1024px) { 
            height: 100%;
        }
        @media screen and (max-width: 667px) { 
            height: 80%;
        }
    `;
    const PdtIn = styled.div`
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        padding: 50px;
        background: rgba(255, 255, 255, 0.4);
        display: grid;
        grid-template-columns: 5fr 3fr;
        z-index:4;
        @media screen and (max-width: 1024px) { 
            grid-template-columns: 1fr;
            grid-template-rows: 5fr 4fr;
            height: 100%;
        padding: 50px;
        }
        @media screen and (max-width: 667px) { 
            grid-template-rows: 2fr 4fr;
            height: 100%;
        padding: 30px;
        }
    `;

    const PdtFePart = styled.div`
        padding: 0 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @media screen and (max-width: 667px) { 
            padding: 0;
        }

    `;

    const PdtImgPart = styled.div`
        display: grid;
        place-content: center;

    `;
    const Img = styled.img`
        /* transform: scale(0.5); */
        width: 700px;
        /* background: RED; */
        @media screen and (max-width: 1024px) { 
            width: 500px;
        }

        @media screen and (max-width: 667px) { 
            width: 250px;
        }
    `;

  return (
    <PdtBox>
        <PdtInBox>
            <PdtIn>
                <PdtImgPart>
                    <Img src={titleimg} />
                </PdtImgPart>
                <PdtFePart>
                    <TileSide icon = {cloud} heading = {'Cloud Based Application'} text = {'This model relies on remote servers for processing logic that is accessed through a web browser with a continual internet connection.'} />
                    <TileSide icon = {user} heading = {'User Friendly Application'} text = {'A user-friendly app means providing a great user experience, whether online or offline.'} />
                    <TileSide icon = {responive} heading = {'Responsive'} text = {'A responsive web app refers to a design that responds effectively to the behavior of the users'} />
                    <TileSide icon = {inventory} heading = {'Real Time Inventory Tracking'} text = {'Real-time inventory management is an automated process of recording sales and purchases through the use of software.'} />
                </PdtFePart>
            </PdtIn>
        </PdtInBox>
    </PdtBox>
  )
}

export default PdtSrcDesc