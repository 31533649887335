import React from 'react'
import styled from 'styled-components'

const HomeTab = ({file}) => {
    const TabSection = styled.section`
        width: 100vw;
        height: 100vh;
        background: #EDEDED;
        padding: 120px 10%;
        display: grid;
        
        @media screen and (max-width: 1024px) { 
            height: 40vh;
            padding: 80px 10%;
        }
        @media screen and (max-width: 558px) { 
            height: 40vh;
        }
    `;
    const TabContainerPart = styled.div`
        position: relative;
        display: grid;
    `;
    const TabContainer = styled.div`
        position: relative;
        /* background: #1C3879; */
        background: ${({bgcol}) => bgcol};
        padding: 10px;
        display: flex;  
        justify-content: flex-end;          
        overflow: hidden;
        position: relative;
            .design-box-btn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%); 
                -webkit-transform: translate(-50%, -50%); 
                display: none;
            }
            .design-box-img {
                margin-top: 100px;
                position: absolute;
                right: -250px;
                height: 100%;
                width: 70%;
                perspective: 600px;
                display: flex;
                justify-content: center;
                transition: all ease-in-out 1.2s;   
                @media screen and (max-width: 1284px) {
                    margin-top: 100px;
                    right: -170px;
                }
                @media screen and (max-width: 1024px) {
                    margin-top: 90px;
                    right: -130px;
                }
                @media screen and (max-width: 768px) {
                    margin-top: 70px;
                    right: -90px;
                }
                @media screen and (max-width: 600px) {
                    margin-top: 60px;
                    right: -65px;
                }
                @media screen and (max-width: 400px) {
                    margin-top: 35px;
                    right: -55px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    transform-style: preserve-3d;
                    transform: rotateY(20deg);
                    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.75);
                    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.75);
                    -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.75);
                }
                &:nth-child(4){
                    transform: scale(0.8);
                    right: 10px;
                    transition: all ease-in-out 1s;
                    @media screen and (max-width: 1284px) {
                        right: 22px;
                    }
                    @media screen and (max-width: 1024px) {
                        right: 22px;
                    }
                    @media screen and (max-width: 768px) {
                        right: 22px;
                    }
                    @media screen and (max-width: 600px) {
                        right: 18px;
                    }
                    
                    @media screen and (max-width: 400px) {
                        right: 13px;
                    }  

                }
            
                &:nth-child(3){
                    transform: scale(0.6);
                    right: 260px;
                    transition: all ease-in-out .8s;  
                    @media screen and (max-width: 1284px) {
                        right: 200px;
                    }
                    @media screen and (max-width: 1024px) {
                        right: 180px;
                    }
                    @media screen and (max-width: 768px) {
                        right: 160px;
                    }
                    @media screen and (max-width: 600px) {
                        right: 125px;
                    }
                    @media screen and (max-width: 400px) {
                        right: 80px;
                    }   

                }
                &:nth-child(2){
                    transform: scale(0.4);
                    right: 470px;
                    transition: all ease-in-out .6s;  
                    @media screen and (max-width: 1284px) {
                        right: 370px;
                    }
                    @media screen and (max-width: 1024px) {
                        right: 330px;
                    }
                    @media screen and (max-width: 768px) {
                        right: 290px;
                    }
                    @media screen and (max-width: 600px) {
                        right: 225px;
                    }
                    @include tab-p {
                        right: 170px;
                    }
                    @media screen and (max-width: 400px) {
                        right: 140px;
                    }  

                }
                &:nth-child(1){
                    margin-top: 160px;
                    transform: scale(0.3);
                    right: 610px;
                    transition: all ease-in-out .4s;  
                    @media screen and (max-width: 1284px) {
                        margin-top: 130px;
                        right: 480px;
                    }
                    @media screen and (max-width: 1024px) {
                        margin-top: 110px;
                        right: 430px;
                    }
                    @media screen and (max-width: 768px) {
                        margin-top: 100px;
                        right: 375px;
                    }
                    @media screen and (max-width: 600px) {
                        margin-top: 90px;
                        right: 290px;
                    }
                    @include tab-p {
                        margin-top: 70px;
                        right: 215px;
                    }
                    @media screen and (max-width: 400px) {
                        margin-top: 50px;
                        right: 180px;
                    }

                }
            }
        

            &:hover {
                .design-box-btn {
                   display: block;
                }
                .design-box-img {
                    &:nth-child(5){
                        transform: scale(1.1); 
                        transition: all ease-in-out .4s;   
                    }
                    &:nth-child(4){
                        transform: scale(0.9); 
                        transition: all ease-in-out .6s;      
                    }
                    &:nth-child(3){
                        transform: scale(0.7);  
                        transition: all ease-in-out .8s;     
                    }
                    &:nth-child(2){
                        transform: scale(0.5);    
                        transition: all ease-in-out 1s;   
                    }
                    &:nth-child(1){
                        transform: scale(0.4);   
                        transition: all ease-in-out 1.2s;    
                    }
                }
            }
    `;

    const TabBox = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background: rgba(28, 56, 121,0.8); */
        background: ${({bgcol}) => bgcol};
        display: grid;
        grid-template-rows: 1fr 50px;
        padding: 35px;
        @media screen and (max-width: 558px) { 
            padding: 20px;
        }
    `;

    const TabHeading = styled.div`
        width: 30%;
        font-family: 'Open Sans', sans-serif;
        color: #EDEDED;font-size: 2em;
        /* font-weight: bold; */
        letter-spacing:1px;
            @media screen and (max-width: 1024px) { 
                font-size: 1.2em;
            }
            @media screen and (max-width: 558px) { 
                font-size: 0.9em;
                width: 60%;
            }

        h4 {
            font-size: 13px;
            font-family: 'Bebas Neue', cursive;
            margin-top: 30px;
            letter-spacing:1px;
            color: #607EAA;
            @media screen and (max-width: 1024px) { 
                font-size: 13px;
            }
            @media screen and (max-width: 558px) { 
                font-size: 10px;
                margin-top: 15px;
            }
        }
    `;
    const TabBtn = styled.div`
        display: flex;
        align-items: flex-end;
    `;

    const TabTap = styled.div`
        width: 130px;
        height: 30px;
        background: #EB4747;
        border-radius:2px;
        display: grid;
        place-content: center;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        letter-spacing:1px;
        text-transform: uppercase;
        color: #EDEDED;
        @media screen and (max-width: 1024px) { 
            width: 100px;
            height: 25px;
            font-size: 10px;
        }
        @media screen and (max-width: 558px) { 
            width: 80px;
            height: 20px;
            font-size: 8px;
        }
    `;

    const FlotIimg = styled.div`
        position: absolute;
        right: -0;
        bottom:  -100px;
        width: 450px;
        height:350px;
        z-index:99999;
    `;
    const FlotImg = styled.img`
        width: 100%;
        height: 100%;
    `;

    

  return (
    <TabSection>
        <TabContainerPart>
            <TabContainer bgcol = {file.bgcolor}>
                <span class="design-box-img">
                    <img src={file.img5} alt="Image web1" />
                </span>
                <span class="design-box-img">
                    <img src={file.img4} alt="Image web1" />
                </span>
                <span class="design-box-img">
                    <img src={file.img3} alt="Image web1" />
                </span>
                <span class="design-box-img">
                    <img src={file.img2} alt="Image web1" />
                </span>
                <span class="design-box-img">
                    <img src={file.img1} alt="Image web1" />
                </span>
                <TabBox bgcol = {file.bglaycolor}>
                    <TabHeading>{file.heading}<h4>{file.subheading}</h4></TabHeading>
                    <TabBtn>
                        <TabTap>explore</TabTap>
                    </TabBtn>
                </TabBox>
            </TabContainer>
                {/* <FlotIimg>
                    <FlotImg src={img} />
                </FlotIimg> */}
        </TabContainerPart>
        
    </TabSection>
  )
}

export default HomeTab