import React from 'react'
import styled from 'styled-components'
import Rotate from 'react-reveal/Rotate';

const HomTabMobile = ({file}) => {
    const TabContainer = styled.section`
        width: 100vw;
        height: 100vh;
        background: #EDEDED;
        padding: 120px 10%;
        display: grid;
        
        @media screen and (max-width: 1024px) { 
            height: 40vh;
            padding: 80px 10%;
        }
        @media screen and (max-width: 558px) { 
            height: 40vh;
        }
    `;
    const Tab = styled.div`
        position: relative;
        display: grid;

        .imgbox {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index:1;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .5s ease-in-out;
            }
        }

        &:hover {
            .imgbox {
            img {
                transform: scale(1.2);
                transition: all .5s ease-in-out;
            }
        }
        }
    `;

    

    const TabDetails = styled.div`
        background: rgba(0, 0, 0,0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index:1;
        background: ${({bgcol}) => bgcol};
        display: grid;
        grid-template-rows: 1fr 50px;
        padding: 35px;
        @media screen and (max-width: 558px) { 
            padding: 20px;
        }
    `;
    const TabHeading = styled.div`
    width: 30%;
    font-family: 'Open Sans', sans-serif;
    color: #EDEDED;font-size: 2em;
    /* font-weight: bold; */
    letter-spacing:1px;
        @media screen and (max-width: 1024px) { 
            font-size: 1.2em;
        }
        @media screen and (max-width: 558px) { 
            font-size: 0.9em;
            width: 60%;
        }

    h4 {
        font-size: 13px;
        font-family: 'Bebas Neue', cursive;
        margin-top: 30px;
        letter-spacing:1px;
        color: #607EAA;
        @media screen and (max-width: 1024px) { 
            font-size: 13px;
        }
        @media screen and (max-width: 558px) { 
            font-size: 10px;
            margin-top: 15px;
        }
    }
`;
const TabBtn = styled.div`
    display: flex;
    align-items: flex-end;
`;

const TabTap = styled.div`
    width: 130px;
    height: 30px;
    background: #EB4747;
    border-radius:2px;
    display: grid;
    place-content: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    letter-spacing:1px;
    text-transform: uppercase;
    color: #EDEDED;
    @media screen and (max-width: 1024px) { 
        width: 100px;
        height: 25px;
        font-size: 10px;
    }
    @media screen and (max-width: 558px) { 
        width: 80px;
        height: 20px;
        font-size: 8px;
    }
`;

const FlotIimg = styled.div`
    position: absolute;
    right: 0;
    bottom:  -150px;
    width: 290px;
    height:530px;
    margin-right: 50px;
    z-index:99999;
    
    @media screen and (max-width: 1024px) { 
        bottom:  -100px;
        width: 170px;
        height:300px;
        margin-right: 50px;
    }
    @media screen and (max-width: 558px) { 
        bottom:  -50px;
        width: 100px;
        height:180px;
        margin-right: 20px;
    }
`;
const FlotImg = styled.img`
    width: 100%;
    height: 100%;
`;



return (
    <TabContainer>
        <Tab>          
            <div className='imgbox'>
                <img src={file.bgimg} />
            </div>
            <TabDetails bgcol = {file.bglaycolor}>
                <TabHeading>{file.heading}<h4>{file.subheading}</h4></TabHeading>
                <TabBtn>
                    <TabTap>explore</TabTap>
                </TabBtn>
            </TabDetails>            
            <FlotIimg>
                <Rotate top right>
                    <FlotImg src={file.floatimg} />
                </Rotate>
            </FlotIimg>
        </Tab>
    </TabContainer>
)
}

export default HomTabMobile