import React from 'react'
import styled from 'styled-components';
import DumyPage from './dumypage';
import OnlyImg from './image_components/only_img';
import DescProductInfo from './product_part/descproductinfo';
import PdtSrcDesc from './product_part/pdtsrcdesc';
import ProductFe from './product_part/produxtfe';



const ZPage = () => {
    const Section = styled.section`
        position: relative;
        z-index: 2;
    `;
  return (
    <Section>    
        <DumyPage />
        <PdtSrcDesc />
        <ProductFe /> 
        <DescProductInfo />
        <OnlyImg />
    </Section>
  )
}

export default ZPage