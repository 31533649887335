import React from 'react'
import styled from 'styled-components';
import { new_feature } from '../../strdata/pagesdata/neo_page/neo_feature';
import Fade from 'react-reveal/Fade';
import { neoFiles } from '../../data/pagesdata/neofile';

const ProductFe = () => {
    const ProductFeBox = styled.section`
        width: 100vw;
        /* height: 130vh; */
        display: grid;
        background: #fff;
        padding: 50px 150px ;
        @media screen and (max-width: 1024px) { 
          padding: 50px 50px;
        }

        @media screen and (max-width: 767px) { 
          padding: 50px 30px;
        }
        
    `;
    const InBox = styled.section`
      display: grid;
      grid-template-rows: auto auto;
      gap:80px;
        
    `;
    
    const BigTFont = styled.section`
      font-family: 'Bebas Neue', cursive;
      line-height: 1;
      font-weight: bold;
      font-size: 9em;
      letter-spacing: 2px;

      background: #3D3D3D;
      background: linear-gradient(to right, #3D3D3D 0%, #EDEDED 30%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width: 1024px) { 
        font-size: 6em;
      }

      @media screen and (max-width: 767px) { 
        font-size: 4em;
      }
        
    `;
    const FeContainer = styled.div`
      /* border: 1px solid red; */
      padding: 0 5% ;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-template-rows: repeat(auto-fit, minmax(120px, 120px));
      grid-gap: 10px;
      row-gap:25px;
        @media screen and (max-width: 1024px) {
      padding: 0 2% ;
        }

        @media screen and (max-width: 767px) { 
      padding: 0 0% ;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
    `;
    const FeBox = styled.div`
      /* background: #262626; */
      /* border: 1px solid red; */
      display: grid;
      grid-template-rows: 1fr 45px;
    `;
    const FeLogoBox = styled.div`
      /* border: 1px solid red; */
      display:grid;
      place-content: center;
    `;
    const FeLogo = styled.img`
    /* border: 1px solid red; */
      width: 75px;
      height: 75px;
    `;
    const FeText = styled.div`
      /* border: 1px solid red; */
      font-family: 'Roboto', sans-serif;
      text-align: center;
      display: grid;
      place-content: center;
      font-weight: bold;
      font-size: 0.8em;
      color: #262626;
    `;
  return (
    <ProductFeBox>
        <InBox>
          <BigTFont>Features</BigTFont>
          <FeContainer>
            {new_feature.map((item,index) => (
              <Fade bottom>
                <FeBox>
                  <FeLogoBox>
                    <FeLogo src={item.img}/>
                  </FeLogoBox>
                  <FeText>{item.title}</FeText>
                </FeBox>
              </Fade>
            ))}
            
          </FeContainer>
        </InBox>
    </ProductFeBox>
  )
}

export default ProductFe