import React from 'react';
import styled from 'styled-components';
import Bounce from 'react-reveal/Bounce';

const TileSide = ({icon,heading,text}) => {
    const FeBox = styled.div`
        width: 100%;
        height: 60px;
        display: grid;
        grid-template-columns: 60px 1fr;
    `;

    const FeSvg = styled.img`
        width:100%;
        height:100%;
    `;
    const FeText = styled.div`
        margin-left:10px;
        display: grid;
        grid-template-rows: 20px 1fr;
    `;
    const FeTextHead = styled.div`
        font-size: 0.8em;
        font-weight: bold;
        font-family: 'Raleway', sans-serif;  
        color:#262626;
    `;
    const FeTextSub = styled.div`
        font-family: 'Lato', sans-serif;
        font-size: 11px;
        color:#413F42;
    `;
  return (
    <Bounce right>
        <FeBox>
            <FeSvg src={icon}/>
            <FeText>
                <FeTextHead>{heading}</FeTextHead>
                <FeTextSub>{text}</FeTextSub>
            </FeText>
        </FeBox>
    </Bounce>
  )
}

export default TileSide