import React from 'react'
import styled from 'styled-components';



const DumyPage = () => {
    const Section = styled.section`
        height: 100vh;
    `;
  return (
    <Section></Section>
  )
}

export default DumyPage