import React from 'react'
import styled from 'styled-components';
import bgimg from '../../media/bgimages/neo_bg_im.jpg';
import neotitle from '../../media/bgimages/neotitleimg.png';



// const ImgBox


const SubTitlePage = () => {
    
    const TitlePageBox = styled.section`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-rows: 1fr 200px;
        z-index: 1;

    `;
    const MidBox = styled.div`
        width: 100%;
        height: 100%;
        background-image: url(${bgimg});
        object-fit: cover;
        display: grid;
        grid-template-columns: 4fr 5fr;
        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }
        @media screen and (max-width: 767px) {
            grid-template-rows: 1.8fr 1fr;
        }
    `;
    const TitleText = styled.div`
        padding:30px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: left;  
    flex-direction: column;
        /* background: red; */
        @media screen and (max-width: 1024px) {
            padding: 0px 45px ;
            text-align: center;  
        }
        @media screen and (max-width: 767px) { 
            padding: 0px 35px ;
        }
    `;
    const TitleImg = styled.div`
        display: flex;
        align-items: flex-end;
        justify-content: center;
        
        /* background: red; */


    `;
    const Img = styled.img`
        /* transform: scale(0.5); */
        width: 900px;
        position: relative;position: relative;
        bottom: -120px;
        /* background: RED; */
        @media screen and (max-width: 1024px) { 
            width: 700px;
        }

        @media screen and (max-width: 767px) { 
            width: 380px;
        }
    `;

    const Text = styled.p`
        /* font-family: 'Raleway', sans-serif;         */
        
      font-family: 'Roboto', sans-serif;
        font-weight: bolder;
        color: #ededed;
        text-transform: capitalize; 
        font-size: 2em;  
        @media screen and (max-width: 767px) { 
            font-size: 2.1em;  
        }   
    `;
    const BigText = styled.span`    
        font-family: 'Raleway', sans-serif;        
        font-weight: bold;
        color: #2C3639;
        text-transform: uppercase; 
        font-size: 1.5em;   
    `;
    const TextBig = styled.span`    
        font-family: 'Raleway', sans-serif;        
        font-weight: bold;
        color: #ededed;
        text-transform: uppercase; 
        font-size: 6rem; 
        line-height: 4rem;
        position: relative;
        left: -10px; 
        @media screen and (max-width: 1024px) { 
             text-align: center;
        /* display: grid;place-content:center; */
        width: 100%;
        left: 0px; 
        }   


    `;

    const LastBox = styled.div`
        /* background: #ededed; */
        background: #fff;
    `;

    return (
        <TitlePageBox>
            <MidBox>
                <TitleText>
                    <TextBig>Neo</TextBig>
                    <Text>
                        We will help your business <BigText>grow 2x</BigText> times using our business software
                    </Text>
                </TitleText>
                <TitleImg>
                    <Img src = {neotitle} />
                </TitleImg>
            </MidBox> 
            <LastBox />         
        </TitlePageBox>
    )
}

export default SubTitlePage