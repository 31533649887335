import { features_img } from "../../images/featur_img";


export const new_feature = [
    {
        title: "Billing POS & Inventory",
        img: features_img["billing"]
    },
    {
        title: "Customer Relationship",
        img: features_img["customer"]
    },
    {
        title: "Customer Support",
        img: features_img["support"]
    },
    {
        title: "GST Implementation",
        img: features_img["tax"]
    },
    {
        title: "SMS & E-Mail",
        img: features_img["mail"]
    },
    {
        title: "Incentive Calculation",
        img: features_img["discount"]
    },
    {
        title: "Sales Performance Analysis",
        img: features_img["anyle"]
    },
    {
        title: "Accurate Reporting & Analysis",
        img: features_img["report"]
    },
    {
        title: "Customizable Themes",
        img: features_img["theme"]
    },
    {
        title: "User Friendly",
        img: features_img["userfriendly"]
    },
    {
        title: "Cloud Based with storage",
        img: features_img["cloudbased"]
    },
    {
        title: "Real Time Inventory Tracking",
        img: features_img["inventoryicon"]
    },
    {
        title: "Sales Return",
        img: features_img["salesreturn"]
    },
    {
        title: "Purchase Return",
        img: features_img["purchasereturn"]
    },
    {
        title: "Sticker | Label Printing",
        img: features_img["lable"]
    },
    {
        title: "Multi Settlement Method",
        img: features_img["satatement"]
    },
    {
        title: "Bill Hold Option",
        img: features_img["hold"]
    },
];