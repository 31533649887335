import React from 'react'
import styled from 'styled-components';
import img from '../../media/bgimages/pcmocup.png';
import Roll from 'react-reveal/Roll';



const DescProductInfo = () => {

    const DumyDiv = styled.div`
      width: 100vw;
      height: 150px;
      background : #fff;
    `;
    const DescProductBox = styled.section`
        width: 100vw;
        height: 35vh;   
        background: #262626;
        display: grid;
        padding: 0 150px;
        @media screen and (max-width: 1024px) {
            padding:70px;
            height: 40vh;
        }
        @media screen and (max-width: 680px) {
            padding:40px;
            height: 50vh;
        }
    `;
    const ClipBox = styled.div`   
       /* margin: 100px 0 ; */
      display: grid;
      grid-template-columns:4fr 5fr;
      @media screen and (max-width: 1024px) {
        grid-template-columns:1fr;
        grid-template-rows:2fr 5fr;
      }
      @media screen and (max-width: 680px) {
        grid-template-rows:50px 1fr;
      }
    `;
    const ImgPart = styled.div`   
      position: relative;
      top: -40%;
      /* border:1px solid red; */
      /* display: grid;
      place-content:center; */
      @media screen and (max-width: 1024px) { 
        top: -180px;
      }
      @media screen and (max-width: 686px) { 
        top: -140px;
      }
    `;
    const Img = styled.img` 
      position: absolute;
      left:0;
      top: 0;
      right:0;
      margin: 0 auto;
      /* top: -40%; */
      width: 500px;
      @media screen and (max-width: 1024px) { 
          width: 500px;
      }
      @media screen and (max-width: 667px) { 
          width: 350px;
      }
      @media screen and (max-width: 397px) { 
          width: 300px;
      }
  `;
    const DesPart = styled.div`   
       /* border:1px solid red; */
       padding: 50px 50px;
       display: grid;
       
      @media screen and (max-width: 667px) { 
        padding: 30px 0;
      }
       /* grid-template-rows: 30px 1fr; */
    `;
    const DesTitle = styled.div`
      /* border:1px solid red; */
      display: flex;
      align-items: center;
        font-family: 'Roboto Flex', sans-serif;
        font-size: 11px;
        color:#EDEDED;
    `;
    const DesText = styled.p`
      display: grid;
      place-content:center;
      /* border:1px solid red; */
      text-align:justify;
        font-family: 'Roboto Flex', sans-serif;
        color: #A2B5BB;
        font-size:18px;
      @media screen and (max-width: 667px) { 
        font-size:16px;
      }
    `;

  return (
    <>
    <DumyDiv />
    <DescProductBox>
        <ClipBox>
        
          <ImgPart>
              <Roll left><Img src={img}/>   </Roll> 
          </ImgPart>
                  
          <DesPart>
            <DesText>
              Our software can automate the
              process of creating, tracking,process
              billing & Invoicing. We have
              developed some of the best features
              which can make the process.
              We know the importance of billing and
              some of the complications associated with
              it. We have developed some of the best
              features which can make the process
              simple and saves time and effort.
            </DesText>
          </DesPart>
        </ClipBox>
    </DescProductBox>

    </>
  )
}

export default DescProductInfo