
import n1 from '../../media/pagesimage/kot/n1.jpg'
import n2 from '../../media/pagesimage/kot/n2.jpg'
import n3 from '../../media/pagesimage/kot/n3.png'

export const kotFiles = {
    heading : "Mobile Application for hotel management.",
    subheading : "kot application",
    link: "",
    bgimg: n1,
    floatimg: n3,
    bgcolor: "#262626",
    bglaycolor : "rgba(0, 0, 0,0.5)",
    // bglaycolor : "rgba(235, 29, 54,0.5)",
};