import React from 'react'
import styled from 'styled-components';
import { BsShop } from 'react-icons/bs';

const ProductBoxCom = ({imgfile,name,links}) => {
    const Box = styled.div`
        position: relative;
        display: grid;
    `;
    const BoxImg = styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;

    `;
    const BoxDetails = styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        padding: 25px;
        display: grid;
        grid-template-rows: 1fr 120px;
        
    `;
    const DetailsPart = styled.div`
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
    `;
    const DetailsBtn = styled.div`
        /* border: 1px solid red; */
        display: flex;
        align-items:center;
        justify-content: center;
    `;
    const BoxLogo = styled.div`
    
    `;
    const BoxHeading = styled.div`
        height: 50px;
        font-family: 'Roboto', sans-serif;
        color: #EDEDED;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px;
        display: flex;
        align-items:center;

    `;
    const BoxBtn = styled.div`
        width: 140px;
        height: 30px;
        background: rgba(0,0,0,0.5);
        display: grid;
        place-content:center;
        font-family: 'Roboto', sans-serif;
        color: #EDEDED;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 11px;
        transition: all .5s ease-in-out;
        cursor: pointer;

        &:hover {
            background: rgba(0,0,0,1);
            transition: all .5s ease-in-out;
        }
    `;
  return (
    <Box>
        <BoxImg src={imgfile} />
        <BoxDetails>
            <DetailsPart>
                <BoxLogo>
                    <BsShop size={30} color= '#EDEDED' />
                </BoxLogo>
                <BoxHeading>
                    {name}
                </BoxHeading>
            </DetailsPart>
            <DetailsBtn>
                <BoxBtn>explore</BoxBtn>
            </DetailsBtn>
        </BoxDetails>
    </Box>
  )
}

export default ProductBoxCom