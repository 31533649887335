import React from 'react'
import styled from 'styled-components';

const ExploreWorksBtn = () => {
    const ExContainer = styled.section`
        width: 100vw;
        height: 200px;
        background: #EDEDED;
        padding: 0 10%;
        display: grid;
        place-content: center;
    `;
    const ExBtn = styled.div`
        position: relative;
        background: #CFD2CF;
        padding:  12px 20px;
        cursor: pointer;

        h5 {
            position: relative;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            letter-spacing:1px;
            text-transform: uppercase;
            color: #EDEDED;
            z-index : 2;
        }

        .exf {
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: #F37878;
            transition: width .5s ease-in-out;z-index : 1;
        }

        &:hover {
            .exf {
                width: 100%;
                transition: width .5s ease-in-out;
            }
        }

    `;
  return (
    <ExContainer>
        <ExBtn><h5>explore more works</h5><div class = 'exf' /></ExBtn>
    </ExContainer>
  )
}

export default ExploreWorksBtn