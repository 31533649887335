
import n1 from '../../media/pagesimage/travelpro/n1.png'
import n2 from '../../media/pagesimage/travelpro/n2.png'
import n3 from '../../media/pagesimage/travelpro/n3.png'
import n4 from '../../media/pagesimage/travelpro/n4.png'
import n5 from '../../media/pagesimage/travelpro/n5.png'

export const travelFiles = {
    heading : "Website for travel booking",
    subheading : "travelpro holiday",
    link: "",
    img1: n1,
    img2: n2,
    img3: n3,
    img4: n4,
    img5: n5,
    bgcolor: "#262626",
    bglaycolor : "rgba(26, 26, 26,0.8)",
};