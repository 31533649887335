import React from 'react'
import styled from 'styled-components';
import neo from '../../media/images/home_page/pos.jpg'
import hotel from '../../media/images/home_page/hotel.jpg'
import garment from '../../media/images/home_page/garment.jpg'
import tailor from '../../media/images/home_page/tailor.jpg'
import { BsShop } from 'react-icons/bs';
import ProductBoxCom from './productbox';

const OnlyProducts = () => {
    const ProductContainer = styled.section`
        width: 100vw;
        height: 100vh;
        background: #EDEDED;
        padding: 120px 10%;
        display: grid;
        @media screen and (max-width: 558px) { 
            height: 100%;
            padding: 60px 10%;
        }
    `;
    const ProductPart = styled.div`
        display: grid;
        grid-template-rows: 25px 1fr;
        gap: 5px;

    `;
    const Heading = styled.div`
        font-family: 'Roboto', sans-serif;
        color: #E6B325;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
    `;
    const ProductBox = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 40px;
        @media screen and (max-width: 1024px) { 
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
        @media screen and (max-width: 600px) { 
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
        }
    `;
    
  return (
    <ProductContainer>
        <ProductPart>
            <Heading>Our Products</Heading>
            <ProductBox>
                <ProductBoxCom imgfile={neo} name='eskay neo' links={''} />
                <ProductBoxCom imgfile={hotel} name='hotel billing' links={''} />
                <ProductBoxCom imgfile={garment} name='billing shoppe' links={''} />
                <ProductBoxCom imgfile={tailor} name='eskay tailor' links={''} />
            </ProductBox>
        </ProductPart>
    </ProductContainer>
  )
}

export default OnlyProducts