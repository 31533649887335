import anyle from '../../media/icon/featurs/anyle.png';
import billing from '../../media/icon/featurs/billing.png';
import cloudbased from '../../media/icon/featurs/cloudbased.png';
import customer from '../../media/icon/featurs/customer.png';
import discount from '../../media/icon/featurs/discount.png';
import hold from '../../media/icon/featurs/hold.png';
import inventoryicon from '../../media/icon/featurs/inventoryicon.png';
import lable from '../../media/icon/featurs/lable.png';
import mail from '../../media/icon/featurs/mail.png';
import purchasereturn from '../../media/icon/featurs/purchasereturn.png';
import report from '../../media/icon/featurs/report.png';
import salesreturn from '../../media/icon/featurs/salesreturn.png';
import satatement from '../../media/icon/featurs/satatement.png';
import support from '../../media/icon/featurs/support.png';
import tax from '../../media/icon/featurs/tax.png';
import theam from '../../media/icon/featurs/theam.png';
import userfriendly from '../../media/icon/featurs/userfriendly.png';

export const features_img ={
    "anyle" : anyle,
    "billing" : billing,
    "cloudbased" : cloudbased,
    "customer" : customer,
    "discount" : discount,
    "hold" : hold,
    "inventoryicon" : inventoryicon,
    "mail" : mail,
    "lable" : lable,
    "purchasereturn" : purchasereturn,
    "report" : report,
    "salesreturn" : salesreturn,
    "satatement" : satatement,
    "support" : support,
    "tax" : tax,
    "theme" : theam,
    "userfriendly" : userfriendly,
};