import React from 'react'
import styled from 'styled-components'
import {  AiFillInstagram, AiOutlineTwitter, AiFillTwitterSquare} from 'react-icons/ai';
// import {  } from 'react-icons/ai';
// import { AiFillTwitterSquare } from 'react-icons/ai';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';



const Footer = () => {
    const FooterContainer = styled.section`
        width: 100vw;
        height: 80vh;
        /* background: #1C3879; */
        background: #000;
        padding: 50px 10% 130px;
        display: grid;
      @media screen and (max-width: 1024px) { 
        height: 100vh;
      }
    `;
    const FooterBox = styled.div`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      @media screen and (max-width: 1024px) { 
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
      }
    `;
    const LinkBox = styled.div`
      display: grid;
      grid-template-rows: auto 1fr 20px;
    `;
    const LinkHeading = styled.div`
      color: #EDEDED;
      /* font-family: 'Roboto', sans-serif; */
      /* font-family: 'Poppins', sans-serif;
      font-family: 'Lato', sans-serif; */
      font-family: 'Open Sans', sans-serif;
      font-size: 2.9em;
    `;
    const LinkBtn = styled.div`
      display: flex;
      align-items : center;
    `;
    const LinkPage = styled.div`
      display: flex;


    `;
    const Links = styled.div`
      border-right: 1px solid #545454;
      display: flex;
      align-items: center;
      color: #545454;
      font-family: 'Roboto', sans-serif;
      text-transform: capitalize;
      font-size: 12px;
      font-weight: bold;
      padding-right: 15px;
      margin-right: 15px;
      letter-spacing:1px;

      &:nth-last-child(1) {
        border-right: none;
      }


    `;
    const Btn = styled.div`
      border: 1px solid #262626;
      width: 180px;
      height: 50px;
      text-transform: uppercase;
      display: grid;
      place-content: center;
      background: #0F0E0E;
      /* font-family: 'Roboto', sans-serif;
      font-family: 'Lato', sans-serif;
      font-family: 'Open Sans', sans-serif; */
      font-family: 'Poppins', sans-serif;
      font-size: 13px;
      color: #EDEDED;



    `;

    const LinkDe = styled.div`
      display: grid;
      grid-template-rows: 30px 1fr 18px;
    `;
    const DeGet = styled.div`
      color: #EDEDED;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      letter-spacing: 1px;
    `;
    const DeAdd = styled.div`
    `;
    const Address = styled.div`
      color: #545454;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      margin-top: 10px;
    `;
    const DeLinks = styled.div`
      color: #545454;
      font-family: 'Poppins', sans-serif;
      font-size: 10px;
    `;
    const SoLink = styled.div`
      margin-top: 15px;
      height: 40px;
      display: flex;
    `;
    const SLink = styled.div`
      width: 40px;
      height: 100%;
      margin-right: 10px;
      display: flex;
      align-items:center;
    `;
  return (
    <FooterContainer>
      <FooterBox>
        <LinkBox>
          <LinkHeading>
            Would you like our help with Development ?
          </LinkHeading>
          <LinkBtn>
            <Btn>start a project</Btn>
          </LinkBtn>
          <LinkPage>
            <Links>about</Links>
            <Links>product</Links>
            <Links>contact</Links>
          </LinkPage>
        </LinkBox>
        <LinkDe>
            <DeGet>Get in Touch</DeGet>
            <DeAdd>
                <Address>1472/D+B+C Bailwad Complex Baswan Galli,</Address>
                <Address>near Laxmi Temple, Belagavi, Karnataka 590001</Address>
                <Address>+91 94489 20928    |   eskaysoftech@gmail.com</Address>
                <SoLink>
                  <SLink><FaFacebookF size={27} color= '#545454'/></SLink>
                  <SLink><AiOutlineTwitter size={27} color= '#545454'/></SLink>
                  <SLink><FaLinkedinIn size={27} color= '#545454'/></SLink>
                  <SLink><AiFillInstagram size={27} color= '#545454'/></SLink>
                </SoLink>
            </DeAdd>
            <DeLinks>© Eskay Softech 2022. All Rights Reserved.</DeLinks>
        </LinkDe>
      </FooterBox>
    </FooterContainer>
  )
}

export default Footer