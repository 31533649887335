import React from 'react'
import styled from 'styled-components';
import img1 from '../../media/images/neo_page/1.png'
import img2 from '../../media/images/neo_page/2.png'
import img3 from '../../media/images/neo_page/3.png'

const OnlyImg = () => {
    const OnlyBox = styled.section`
        width: 100vw;
        height: 100%;
        display: grid;
        background: #fff;
        padding: 100px 150px;
        
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 20px;
        @media screen and (max-width: 1024px) { 
          padding: 100px 50px;
          
        grid-template-columns: 1fr ;
        grid-template-rows:  1fr;
        }

        @media screen and (max-width: 767px) { 
          padding: 100px 30px;
        }
        
    `;

    const ImgBox = styled.img`
        border: 20px solid #262626;
        width: 100%;
        height: 100%;
    `;
  return (
    <OnlyBox>
        <ImgBox src={img1}/>
        <ImgBox src={img2}/>
        <ImgBox src={img3}/>
        <ImgBox src={img1}/>
    </OnlyBox>
  )
}

export default OnlyImg