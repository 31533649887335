import React from 'react'
import FullImg from '../components/image_components/fullimg_con'
import HmTitlePage from '../pagecomponents/homepage/hm_titlepage'
import bgimg from '../media/images/home_page/bgimg.jpg'
import styled from 'styled-components'
import HomeProductModel from '../pagecomponents/homepage/home_product'
import HomeTab from '../pagecomponents/homepage/home_tabpage'
import { neoFiles } from '../data/pagesdata/neofile'
import { travelFiles } from '../data/pagesdata/travelfile'
import ExploreWorksBtn from '../pagecomponents/homepage/home_explorebtn'
import ShopLogos from '../pagecomponents/homepage/home_shop'
import MapAdd from './addmap'
import Footer from './footer'
import OnlyProducts from '../pagecomponents/homepage/home_onlyproduct'
import HomeAbout from '../pagecomponents/homepage/home_about'
import HomTabMobile from '../pagecomponents/homepage/home_tabmobile'
import { kotFiles } from '../data/pagesdata/kotfile'

const HomePage = () => {
  const Trialpage = styled.div`
    width: 100vw;
    height:100vh;
    background: #fff;
  `;
  return (
    <>
      <FullImg bgimg={bgimg} />
      <HmTitlePage />
      {/* <HomeProductModel /> */}
      <OnlyProducts />
      <HomeTab file = {neoFiles}/>
      <HomeTab file = {travelFiles}/>
      <HomTabMobile file={kotFiles} />
      <ShopLogos />
      <ExploreWorksBtn />
      {/* <MapAdd /> */}
      <HomeAbout />
      <Footer />
    </>

  )
}

export default HomePage