import React from 'react'
import SubTitlePage from '../components/image_components/subtitlepage'
import ZPage from '../components/zpage'

const NeoPage = () => {
  return (
    <>
        <SubTitlePage />
        <ZPage/>  
    </>
  )
}

export default NeoPage