import React from 'react'
import styled from 'styled-components'

const FullImg = ({bgimg}) => {
    const FullImg = styled.section`
        width: 100vw;
        height: 100vh;
        background-image: url(${bgimg});
        /* border: 22px solid red; */
    `;
  return (
    <FullImg>

    </FullImg>
  )
}

export default FullImg