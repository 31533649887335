import React, {useEffect} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
  } from "react-router-dom";
import GlobleStyle from './globelStyle';
import HomePage from './Pages/HomePage'
import NeoPage from './Pages/NeoPage';
// import Aos from 'aos';
// import 'aos/dist/aos.css';

const App = () => {
    useEffect(() => {
        window.scroll(0,0);
        // Aos.init({ duration: 2000 });
    },[]);
  return (
    <>
        <GlobleStyle />
        <Routes>
            <Route exact path = "/" element = {<HomePage />} />
            <Route exact path = "/product" element = {<NeoPage />} />
        </Routes>
    </>
  )
}
export default App