
import travelproimg from '../../media/pagesimage/shoplogos/travelpro.png'

export const shopLogoFiles = [
    travelproimg,
    travelproimg,
    travelproimg,
    travelproimg,
    travelproimg,
    travelproimg,
    travelproimg,
    travelproimg,
    travelproimg,
    travelproimg

];