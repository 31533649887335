import React from 'react';
import styled from 'styled-components';
import flutter from '../../media/icon/picon/flutter.png';
import net from '../../media/icon/picon/net.png';
import mysql from '../../media/icon/picon/mysql.png';
import php from '../../media/icon/picon/php.png';
import javascript from '../../media/icon/picon/javascript.png';
import react from '../../media/icon/picon/react.png';
import sass from '../../media/icon/picon/sass.png';

const HmTitlePage = () => {
    const TitleContainer = styled.div`
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding : 0 10% 100px;
      display:grid;
      background: linear-gradient(0deg, rgba(0,0,0,0.8354692218684349) 0%, rgba(0,0,0,0.6449930313922444) 48%, rgba(0,0,0,0.5021358885351015) 100%);
      /* background: linear-gradient(0deg, rgba(84,99,255,0.8354692218684349) 0%, rgba(84,99,255,0.6449930313922444) 48%, rgba(84,99,255,0.5021358885351015) 100%); */

    `;
    const InBox = styled.div`
      padding-top: 80px;
      display: grid;
      grid-template-rows: 2fr auto;
    `;
    
    const TextContainer = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

    `;
    
    const LogoContainer = styled.div`
      /* border:1px solid red; */
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 50px;
    `;
    const TextBig = styled.div`
      /* color:#000; */
      font-family: 'Bebas Neue', cursive;
      font-size:7rem;
      font-weight: bold;
      line-height: 5.5rem;
      letter-spacing: 5px;
      background: #9C9C9C;
      background: linear-gradient(to right, #9C9C9C 55%, #262626 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width: 600px) { 
        
      font-size:5rem;
      line-height: 4rem;
      }


    `;
    const TextSmall = styled.div`
      color:#000;
      font-family: 'Bebas Neue', cursive;
      font-size:3em;
      letter-spacing: 5px;
      font-weight: bold;
      background: #9C9C9C;
      background: linear-gradient(to right, #9C9C9C 55%, #454545 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width: 600px) { 
        
      font-size:2rem;
      /* line-height: 4rem; */
      }
    `;
    const Logo = styled.img`
      height: 40px;
      margin: 50px 80px 50px 0;
      @media screen and (max-width: 1024px) { 
        height: 30px;
        margin: 30px 50px 30px 0;
      }
    `;
  return (
    <TitleContainer>
      <InBox>
        <TextContainer>
          <TextSmall>Think</TextSmall>
          <TextBig>Outside</TextBig>
          <TextSmall>Of the box</TextSmall>
        </TextContainer>
        <LogoContainer>
          <Logo src={flutter} />
          <Logo src={net} />
          <Logo src={mysql} />
          <Logo src={javascript} />
          <Logo src={php} />
          <Logo src={react} />
          <Logo src={sass} />
        </LogoContainer>
      </InBox>
    </TitleContainer>
  )
}

export default HmTitlePage