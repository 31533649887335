import React from 'react'
import styled from 'styled-components';
import { shopLogoFiles } from '../../data/pagesdata/shoplogofile';
import travelpro from '../../media/pagesimage/shoplogos/travelpro.png'

const ShopContainer = styled.section`
        width: 100vw;
        /* height: 100%; */
        background: #EDEDED;
        padding: 120px 10%;
        display: grid;
        
        @media screen and (max-width: 1024px) { 
            /* height: 40vh; */
            padding: 80px 10%;
        }
        @media screen and (max-width: 558px) { 
            /* height: 40vh; */
        }
    `;

    const LogoContainer = styled.div`
    
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(70px, 90px));
        grid-gap: 20px;
        row-gap:25px;
        @media screen and (max-width: 600px) { 
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
        /* @media screen and (max-width: 1024px) {
            padding: 0 2% ;
        }

        @media screen and (max-width: 767px) { 
            padding: 0 0% ;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        } */
    `;
    const Logobox = styled.div`
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;

    `;
    const LogoFile = styled.img`
        width: 120px;
        height: 65px;
        opacity: 0.5;
    `;

const ShopLogos = () => {
  return (
    <ShopContainer>
        <LogoContainer>
            {shopLogoFiles.map((logo, index) => (
                <Logobox>                    
                    <LogoFile src={logo} />
                </Logobox>
            ))}
        </LogoContainer>
    </ShopContainer>
  )
}

export default ShopLogos