
import n1 from '../../media/pagesimage/neo/n1.png'
import n2 from '../../media/pagesimage/neo/n2.png'
import n3 from '../../media/pagesimage/neo/n3.png'
import n4 from '../../media/pagesimage/neo/n4.png'
import n5 from '../../media/pagesimage/neo/n5.png'

export const neoFiles = {
    heading : "Web Application for inventory management.",
    subheading : "Billing software",
    link: "",
    img1: n1,
    img2: n2,
    img3: n3,
    img4: n4,
    img5: n5,
    bgcolor: "#1C3879",
    bglaycolor : "rgba(28, 56, 121,0.8)",
};