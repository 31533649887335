import React from 'react'
import styled from 'styled-components'
import design from '../../media/images/home_page/design.jpg'
import developer from '../../media/images/home_page/developer.jpg'
import marketing from '../../media/images/home_page/marketing.jpg'

const HomeAbout = () => {
    const AboutCotainer = styled.section`
        width: 100vw;
        height: 100vh;
        background: #EDEDED;
        padding: 120px 10%;
        display: grid;
        @media screen and (max-width: 558px) { 
            height: 100%;
            padding: 60px 10%;
        }
    `;
    const Box = styled.div`
        /* background: #1C3879; */
        background: #CFD2CF;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        padding: 40px;
        @media screen and (max-width: 558px) { 
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }
    `;
    const BoxAbout = styled.div`
        display: grid;
        grid-template-rows: auto auto auto 1fr;
    `;
    const AboutHead = styled.div`
        height: 25px;
        font-family: 'Roboto', sans-serif;
        color: #E6B325;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
    `;
    const AboutTitle = styled.div`
        font-family: 'Bebas Neue', cursive;
        /* font-family: 'Poppins', sans-serif; */
        color: #fff;
        text-transform: uppercase;
        /* font-weight: bold; */
        font-size: 3em;
        span {                 
            color: #EB1D36;
        }  
            
    `;
    const AboutDesc = styled.div`
        font-size:14px;
        font-family: 'Poppins', sans-serif;
        text-align:justify;
        color: #ededed;
        @media screen and (max-width: 558px) { 
            margin-bottom: 40px;
        }
    `;
    const AboutBtnBox = styled.div`
        display: flex;
        align-items:flex-end;
    `;
    const AboutBox = styled.div`
        width: 130px;
        height: 30px;
        /* background: rgba(0,0,0,0.6); */
        background: #EB1D36;
        display: grid;
        place-content: center;
        color:#EDEDED;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
    `;
    const BoxMenu = styled.div`
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 30px;
    `;
    const Menu = styled.div`
        position: relative;
        display: grid;

    `;
    const MenuImg = styled.div`
        width: 100%;
        height: 100%;
        background-image: url(${({imgfile}) => imgfile});
    `;
    const MenuBox = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        /* background: rgba(28, 56, 121,0.8); */
        padding: 20px;
        color: #ededed;
        font-family: 'Open Sans', sans-serif;
        font-size: 2em;
        letter-spacing: 1px;

    `;
  return (
    <AboutCotainer>
        <Box>
            <BoxMenu>
                <Menu>
                    <MenuImg imgfile={design} />
                    <MenuBox>
                        Design
                    </MenuBox>
                </Menu>
                <Menu>
                    <MenuImg imgfile={developer} />
                    <MenuBox>
                        Development
                    </MenuBox>
                </Menu>
                <Menu>
                    <MenuImg imgfile={marketing} />
                    <MenuBox>
                        Marketing
                    </MenuBox>
                </Menu>
            </BoxMenu>
            <BoxAbout>
                <AboutHead>about us</AboutHead>
                <AboutTitle>over <span>25 years</span> of experience gained</AboutTitle>
                <AboutDesc>The Eskay Infotech is founded by Mr.Shivayogi Kardiguddi, he is a passionate and
                    dynamic software professional. He has the vision to contribute to the growth of small
                    Enterprises, Service industry, SME,MSEME and other retail businesses. The in-depth
                    knowledge of business processes,compliance requirements and understanding of pain
                    areas at every level of user makes the software offerings of Eskay infotech unique</AboutDesc>
                <AboutBtnBox>
                    <AboutBox>read more</AboutBox>
                </AboutBtnBox>
            </BoxAbout>
        </Box>
    </AboutCotainer>
  )
}

export default HomeAbout